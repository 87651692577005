import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FeatureSection = ({
                          imagePosition = "left",
                          header = "Header",
                          backgroundColorClass = "bg-f1f8fb",
                          textBlock1,
                          textBlock2,
                          textBlock3,
                          image
                        }) => {
  return (
    <section className={"services-area pt-4 pb-70 " +backgroundColorClass}>
      <Container>
        <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Feature Highlight</span>
        <h2>{header}</h2>
        <Row className={"mt-5"}>
          {imagePosition === "left" &&
          <Col md={6} xs={12}>
            {image}
          </Col>
          }
          {imagePosition === "right" &&
          <Col md={{ order: "first", span: 5 }} xs={{ order: "last", span: 12 }}>
            {textBlock1 && <p className={"pt-lg-0 pt-3"}>
              {textBlock1}
            </p>
            }
            {textBlock2 && <p>
            {textBlock2}
              </p>
            }
            {textBlock3 && <p>
            {textBlock3}
              </p>
            }
          </Col>
          }

          {imagePosition === "left" &&
          <Col md={{ offset: 1, span: 5 }} xs={{ span: 12 }}>
            {textBlock1 && <p className={"pt-lg-0 pt-3"}>
              {textBlock1}
            </p>
            }
            {textBlock2 && <p>
              {textBlock2}
            </p>
            }
            {textBlock3 && <p>
              {textBlock3}
            </p>
            }
          </Col>
          }
          {imagePosition === "right" &&
          <Col md={{ span: 6, offset: 1, order: "last" }} xs={{span: "12", order: "first"}}>
            {image}
          </Col>
          }
        </Row>
      </Container>
    </section>
  )
}

export default FeatureSection
