import React from "react"
import { Link } from "gatsby"
import { useMatch } from "@reach/router"
import DownloadBox from "./DownloadBox"

const Sidebar = ({ withContactForm = false }) => {

  return (
    <div className="services-details-info">
      <ul className="services-list">
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/"
                className={useMatch("/lagerverwaltung/") && "active"}>
            Übersicht
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/netstorsys/kommissionierung/"
                className={useMatch("/lagerverwaltung/netstorsys/kommissionierung/") && "active"}>
            Kommissionierung
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/netstorsys/retouren/"
                className={useMatch("/lagerverwaltung/netstorsys/retouren/") && "active"}>
            Retouren
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/netstorsys/warenbewegungen/"
                className={useMatch("/lagerverwaltung/netstorsys/warenbewegungen/") && "active"}>
            Warenbewegungen
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/netstorsys/auftragsuebersicht/"
                className={useMatch("/lagerverwaltung/netstorsys/auftragsuebersicht/") && "active"}>
            Auftragsübersicht
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/netstorsys/lagerorte/"
                className={useMatch("/lagerverwaltung/netstorsys/lagerorte/") && "active"}>
            Lagerorte
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/netstorsys/inventur/"
                className={useMatch("/lagerverwaltung/netstorsys/inventur/") && "active"}>
            Inventur
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/lagerverwaltung/netstorsys/schnittstellen/"
                className={useMatch("/lagerverwaltung/netstorsys/schnittstellen/") && "active"}>
            Schnittstellen
          </Link>
        </li>
      </ul>

      <div className={"d-none d-lg-block"}>
        <DownloadBox />
      </div>


      {withContactForm &&
      <div className="services-contact-info">
        <h3>Contact Info</h3>

        <ul>
          <li>
            <div className="icon">
              <i className="bx bx-user-pin" />
            </div>
            <span>Phone:</span>
            <a href="tel:+21453545413">+2145 354 5413</a>
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-map" />
            </div>
            <span>Location:</span>
            New York, USA
          </li>
          <li>
            <div className="icon">
              <i className="bx bx-envelope" />
            </div>
            <span>Email:</span>
            <a href="mailto:hello@tarn.com">hello@tarn.com</a>
          </li>
        </ul>
      </div>
      }
    </div>
  )
}

export default Sidebar
