import React from "react"

const DownloadBox = () => {
  return (
    <div className="download-file">
      <h3>Downloads / Broschüren</h3>

      <ul>
        <li>
          <a href="/dl/netstorsys_flyer.pdf">
            NETSTORSYS Produktflyer <i className="bx bxs-file-pdf"/>
          </a>
        </li>
        <li>
          <a href="/dl/netstorsys_casestudy_thomasphilipps.pdf">
            Case Study: Thomas Philipps <i className="bx bxs-file-txt"/>
          </a>
        </li><li>
          <a href="/dl/netstorsys_casestudy_plenge.pdf">
            Case Study: Plenge GmbH <i className="bx bxs-file-txt"/>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default DownloadBox
