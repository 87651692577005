import React from "react"
import kommissionierungHeader from "../../../assets/images/products/netstorsys/kommissionierung_header.svg"
import wmWarenbewegungen from "../../../assets/images/products/netstorsys/wm-warenbewegungen.svg"
import NetstorsysSidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import project3 from "../../../assets/images/products/netstorsys/kommissionierung_screen.svg"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import FeatureSection from "../../App/FeatureSection"
import { Link } from "gatsby"

const Kommissionierung = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={kommissionierungHeader} alt="Kommissionierung" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETSTORSYS</span>
                <h1 className={"pt-3"}>Effiziente Kommissionierung im Lager</h1>
                <p>
                  Die Kommissionierung ist neben der Einlagerung der am häufigsten ausgeführte Vorgang im gesamten
                  Lager.
                  NETSTORSYS sendet zu kommissionierende Aufträge direkt auf mobile Erfassungsgeräte der Mitarbeiter,
                  unterscheidet dabei zwischen verschiedenen Auftragstypen wie Single- oder Multi-Liner und wendet die
                  jeweils richtige Entnahmestrategie an.
                </p>

                <WichtigeMerkmale items={[
                  "Strategie je Sortiment",
                  "Laufwegsoptimierung",
                  "Moderne Oberfläche",
                  "Fehlpicks verhindern",
                  "Qualitätsprüfung am Lagerort"
                ]} image={<img src={wmWarenbewegungen}
                               alt="Kommissionierung Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <p>
                  NETSTORSYS unterstützt sowohl die ein-, als auch die zweistufige Kommissionierung. Für Umgebungen ohne
                  WLAN-Infrastruktur bietet NETSTORSYS außerdem papierbasierte Picklisten mit nachgelagerter
                  Informationsverarbeitung. Das System unterstützt das Einlagern und Kommissionieren von Artikeln und
                  Ladungsträgern. Somit ist es möglich komplette Aufträge zu lagern und Cross-Docking Operationen zu
                  ermöglichen.
                </p>

                <h2>Auftragsorientierte serielle Kommissionierung</h2>

                <p>
                  Bei der auftragsorientierten seriellen Kommissionierung werden
                  die Aufträge in den verschiedenen Lagerbereichen nacheinander
                  bearbeitet. Dies ist das Standardverfahren in NETSTORSYS, da
                  hier die niedrigsten Anforderungen an Personal und Technik
                  gestellt werden können. Die Fehlerquote liegt hier in der Regel
                  am niedrigsten.
                </p>

                <h2>Auftragsorientierte parallele Kommissionierung</h2>
                <p>
                  Auch zweistufige Kommissionierung genannt. Bei der
                  auftragsorientierten, parallelen Kommissionierung ist eine
                  Kommissioniermethode, bei der einzelne Aufträge zunächst
                  aufgesplittet und anschließend die Teilaufträge in den
                  verschiedenen Lagerzonen parallel abgearbeitet werden. Dies
                  erfordet einen zweiten Schritt der Zusammenführung der
                  gesplitteten Aufträge. NETSTORSYS unterstützt dieses Modell
                  ebenfalls. Die Anforderung sind jedoch höher als bei der
                  seriellen Kommissionierung.
                </p>

                <div className={"d-flex flex-md-row flex-column"}>
                  <Link to="/lagerdigitalisierung-teil-2-vorteile-digitaler-kommissionierung/" className="btn btn-primary my-2 btn-lg">
                    Mehr im Blog
                  </Link>
                </div>

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection product={"Netstorsys"} />

              </div>
            </Col>

            <Col lg={4} md={12}>
              <NetstorsysSidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<img src={project3} alt={"Auftragsübersicht"} />}
                      header={"Kommissionierung in NETSTORSYS"}
                      textBlock1={"In der Auftragsübersicht kann direkt über die Oberfläche von NETSTORSYS der Status eingesehen werden. "+
                      "Durch die Anbindung an bestehende Vorsystemen sind diese immer aktuell und spiegeln somit auch dessen "+
                      "Stand wieder. "+
                      "Im Detail ist direkt ersichtlich, welche Positionen eines Auftrags genügend Bestand aufweisen und welche "+
                      "nicht erfüllbar sind. Angepasst an Ihre Bedürfnisse lassen sich mit diesen Kennzahlen beispielsweise "+
                      "bestimmte Prozesse starten oder Warnungen auslösen. Somit können Sie zeitnah auf Bestandsprobleme "+
                      "reagieren oder diese sogar automatisch lösen lassen."}
                      textBlock2={<span>Durch Speicherung von Ladungsträgern an Lagerorten ist es jederzeit möglich innerhalb der einstufigen
                Kommissionierung den Status innerhalb der Kommissionierung zu erkennen. Wenn z.B. der Kommissionierer
                mit einem Rollwagen, der mit 6 Wannen bzw. Aufträgen bestückt ist, die Entnahmen tätigt, so weiß
                NETSTORSYS nicht nur welche Ladungsträger mit welchem Auftrag verknüpft sind, sondern ist auch in der
                Lage zu erkennen, wieviel Waren in dem Lauf noch gepickt werden müssen.</span>}
                      textBlock3={<span>Der Pickprozess ist übrigens auch auf den Endgeräten webbasiert. Dies bedeutet das NETSTORSYS maximal
                unabhängig von der unterliegenden Hardware ist. Damit können auch moderne Funktionen und Oberflächen
                angeboten werden, die das Handling erheblich erleichtern. Jeder der schon einmal einen neuen
                Hardwarehersteller für das Picking in einem Logistiklager eingeführt hat, weiß den Vorteil zu schätzen.</span>}
      />
    </>
  )
}

export default Kommissionierung
