import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Kommissionierung from '../../../components/ProductDetails/netstorsys/Kommissionierung'
import Seo from "../../../components/App/Seo"

const KommissionierungsPage = () => {
  return (
    <Layout>
      <Seo title={"Effiziente Kommissionierung durch smarte Prozesse"}
           description={"Der Pickprozess ist neben der Einlagerung der am häufigsten ausgeführte Vorgang im gesamten " +
           "Warehouse. NETSTORSYS bietet effiziente Strategien an."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Kommissionierung/>
      <Footer />
    </Layout>
  );
}

export default KommissionierungsPage
